import styled, { createGlobalStyle } from 'styled-components';
import zIndices from 'pubweb-smokey/dist/components/zIndex';

export const GlobalStyle = createGlobalStyle`
    body.modal-lock {
        overflow: hidden;
    }
`;

const ModalStyles = styled.div`
  .close-modal {
    cursor: pointer;
    position: absolute;
    top: -22px;
    right: -8px;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #fff;
      }
    }
  }

  .modal-container {
    background-color: white;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: ${zIndices.modal};
  }

  .modal-content {
    max-height: 80vh;
    overflow: hidden auto;
  }

  .modal-filter {
    background: rgba(0, 0, 0, 0.5);
    height: 200vh;
    left: -50vw;
    position: fixed;
    top: -50vh;
    width: 200vw;
    z-index: ${zIndices.modal};
  }
`;

export default ModalStyles;
