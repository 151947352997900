import React from 'react';
import PropTypes from 'prop-types';
import { SnippetSectionStyles } from './SnippetSection.styled';
import { slugify } from '@utils/studioUtils';
import SnippetOneColumn from '../SnippetOneColumn/SnippetOneColumn';
import SnippetTwoColumn from '../SnippetTwoColumn/SnippetTwoColumn';
import { extractColors } from '@utils/utils';
import { getContentTextAlignment } from '@utils/contentful';

const SnippetSection = ({ section, snippetHeaderColor = '' }) => {
  return (
    <SnippetSectionStyles id={slugify(section?.snippetSectionHeader)}>
      {section?.snippetSectionHeader &&
        section?.snippetLayout === 'Two columns' && (
          <div className="snippet-section-header">
            <h2
              style={{
                color: extractColors(section?.headerTextColor),
                textAlign: getContentTextAlignment(section?.alignText),
              }}
            >
              {section?.snippetSectionHeader}
            </h2>
          </div>
        )}
      {section?.snippetLayout === 'One column per snippet (default)' && (
        <SnippetOneColumn
          {...section}
          snippetHeaderColor={snippetHeaderColor}
        />
      )}
      {section?.snippetLayout === 'Two columns' && (
        <SnippetTwoColumn section={section} />
      )}
    </SnippetSectionStyles>
  );
};

export default SnippetSection;

SnippetSection.propTypes = {
  section: PropTypes.object,
  snippetHeaderColor: PropTypes.string,
};
