import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { SnippetTwoColumnStyles } from './SnippetTwoColumn.styled';
import {
  formatHttpsUrl,
  extractColors,
  getSlug,
  isUrlInternal,
} from '@utils/utils';
import Markdown from '@components/Shared/Markdown/Markdown';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import {
  tablet_container_maxwidth,
  sm_desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import {
  getContentTextAlignment,
  getContentFlexAlignment,
} from '@utils/contentful';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';

const SnippetTwoColumn = ({ section }) => {
  const [imgQuery, setImgQuery] = useState('?w=800&fm=webp');
  useWindowResize(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > sm_desktop_breakpoint) {
        setImgQuery('?w=1200&fm=webp');
      } else if (window.innerWidth > tablet_container_maxwidth) {
        setImgQuery('?w=800&fm=webp');
      } else {
        setImgQuery('?w=506&fm=webp');
      }
    }
  }, [tablet_container_maxwidth, sm_desktop_breakpoint]);

  return (
    <SnippetTwoColumnStyles
      $bgColor={extractColors(section?.sectionBackgroundColor)}
    >
      <div className="snippet-section-container">
        {section?.snippets?.map((snippet, i) => {
          return (
            <div key={`snippet-item-${i + 1}`} className="snippet-item">
              <div className="snippet-image">
                <Image
                  src={formatHttpsUrl(
                    `${snippet?.snippetIcon?.file?.url}${imgQuery}`
                  )}
                  alt={snippet?.snippetIcon.description}
                  layout="fill"
                />
              </div>

              {snippet?.snippetHeader && (
                <h4
                  style={{
                    textAlign: getContentTextAlignment(snippet?.alignText),
                    color: extractColors(snippet?.headerTextColor),
                  }}
                  className="snippet-header"
                >
                  {snippet?.snippetHeader}
                </h4>
              )}
              {snippet?.snippetText && (
                <div
                  style={{
                    color: extractColors(snippet?.snippetTextColor),
                    textAlign: getContentTextAlignment(snippet?.alignText),
                  }}
                >
                  <Markdown markdown={snippet?.snippetText} />
                </div>
              )}
              <ul
                className="snippet-links"
                style={{
                  justifyContent: getContentFlexAlignment(snippet?.alignText),
                }}
              >
                {snippet?.snippetLinks?.map((sl, i) => {
                  return (
                    <li
                      key={`snippet-link-${i + 1}`}
                      className="snippet-link"
                      style={{
                        color: extractColors(sl?.buttonAndtextColors)?.[1],
                        fill: extractColors(sl?.buttonAndtextColors)?.[1],
                      }}
                    >
                      <Link
                        href={
                          isUrlInternal(sl.link) ? getSlug(sl.link) : sl.link
                        }
                      >
                        <div className="snippet-link-text">
                          {sl.linkText}
                          <div className="snippet-icon">
                            <StArrowRightSvg />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </SnippetTwoColumnStyles>
  );
};

export default SnippetTwoColumn;

SnippetTwoColumn.propTypes = {
  section: PropTypes.shape({
    alignText: PropTypes.string,
    snippets: PropTypes.arrayOf(
      PropTypes.shape({
        alignText: PropTypes.string,
        snippetHeader: PropTypes.string,
        headerTextColor: PropTypes.string,
        snippetIcon: PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        snippetText: PropTypes.string,
        snippetTextColor: PropTypes.string,
        snippetLinks: PropTypes.arrayOf(
          PropTypes.shape({
            linkText: PropTypes.string,
            link: PropTypes.string,
            displayLinkAs: PropTypes.string,
            buttonAndtextColors: PropTypes.string,
            contentType: PropTypes.string,
          })
        ),
        contentType: PropTypes.string,
      })
    ),
    sectionBackgroundColor: PropTypes.string,
  }),
};
