import styled from 'styled-components';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const SnippetSectionStyles = styled.section`
  .snippet-section-header {
    padding: 0px 16px;
  }

  @media screen and (min-width: ${desktop_breakpoint}px) {
    .snippet-section-header {
      width: 80%;
      margin: auto;
      padding: 0px;
      max-width: 1400px;
    }
  }
`;
