import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageModalVideoStyles, {
  VideoModal,
  VideoWrapper,
} from './ImageModalVideo.styled';
import Modal from '@components/Shared/Modal/Modal';
import PlayIcon from 'pubweb-smokey/dist/images/svg/iconography-16x16/play-button-circle.svg';
import { formatHttpsUrl } from '@utils/utils';
import Image from 'next/image';

export default function ImageModalVideo({
  altText,
  className,
  wistiaID,
  ...props
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <ImageModalVideoStyles>
      <a
        className={`link-wrap${className ? ' ' + className : ''}`}
        onClick={() => setShowModal(true)}
      >
        <div className="preview-image">
          <Image
            src={formatHttpsUrl(`${props.image}?w=800&fm=webp`)}
            alt={altText}
            width={657}
            height={441}
          />
          <div className="play-icon">
            <PlayIcon viewBox="0 0 16 16" />
          </div>
        </div>
      </a>

      {showModal && (
        <VideoModal>
          <Modal onClose={() => setShowModal(false)}>
            <VideoWrapper>
              <div
                className={`wistia_embed wistia_async_${wistiaID} autoPlay=true copyLinkAndThumbnailEnabled=false`}
                style={{ position: 'relative' }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: '100%',
                    left: 0,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    transition: 'opacity 200ms',
                    width: '100%',
                  }}
                >
                  <Image
                    src={`https://fast.wistia.com/embed/medias/${wistiaID}/swatch/`}
                    style={{
                      filter: 'blur(5px)',
                      height: '100%',
                      objectFit: 'contain',
                      width: '100%',
                    }}
                    alt={altText}
                    aria-hidden="true"
                    layout="fill"
                  />
                </div>
              </div>
            </VideoWrapper>
          </Modal>
        </VideoModal>
      )}
    </ImageModalVideoStyles>
  );
}

ImageModalVideo.propTypes = {
  altText: PropTypes.any,
  className: PropTypes.any,
  image: PropTypes.any,
  wistiaID: PropTypes.any,
};
