import React from 'react';
import PropTypes from 'prop-types';
import PageTransition from '@components/Shared/PageTransition/PageTransition';
import { extractColors } from '@utils/utils';
import { PageTransitionStyles, MiniNavStyles } from './PageTransition.styled';

const Wrapper = ({
  hasNavLinks,
  titleBackgroundColor = '',
  backgroundColor = '',
  textColor = '',
  overviewTextColor = '',
  children,
}) => {
  return (
    <>
      {hasNavLinks ? (
        <MiniNavStyles
          $backgroundColor={extractColors(backgroundColor)}
          $textColor={extractColors(textColor)}
        >
          <div className="mini-nav-container">{children}</div>
        </MiniNavStyles>
      ) : (
        <PageTransitionStyles
          style={{ backgroundColor: extractColors(backgroundColor) }}
          $backgroundColor={extractColors(titleBackgroundColor)}
          $textColor={extractColors(textColor)}
          $overviewTextColor={extractColors(overviewTextColor)}
        >
          {children}
        </PageTransitionStyles>
      )}
    </>
  );
};

Wrapper.propTypes = {
  hasNavLinks: PropTypes.bool,
  titleBackgroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  overviewTextColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const PageTransitionContainer = (props) => {
  return (
    <Wrapper
      hasNavLinks={props.navLinks?.length > 0}
      titleBackgroundColor={props.titleBackgroundColor}
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      overviewTextColor={props.overviewTextColor}
    >
      <PageTransition {...props} />
    </Wrapper>
  );
};

export default PageTransitionContainer;

PageTransitionContainer.propTypes = {
  transitionTitle: PropTypes.string,
  alignText: PropTypes.string,
  titleBackgroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  anchorLink: PropTypes.string,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      transitionTitle: PropTypes.string,
      anchorLink: PropTypes.string,
    })
  ),
  navLinksTextColor: PropTypes.string,
  transitionImage: PropTypes.object,
  overview: PropTypes.string,
  overviewLinks: PropTypes.array,
  overviewTextColor: PropTypes.string,
};
