import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import { SnippetOneColumnStyles } from './SnippetOneColumn.styled';
import { formatHttpsUrl, extractColors } from '@utils/utils';
import DynamicLink from '@components/Shared/DynamicLink/DynamicLink';
import Markdown from '@components/Shared/Markdown/Markdown';
import { getContentTextAlignment } from '@utils/contentful';

const SnippetIcon = ({ snippet, index }) => {
  return (
    <>
      {snippet.snippetIcon?.file?.contentType === 'image/svg+xml' ? (
        <div className={`snippet-icon-${index} icon-common`}>
          <Image src={snippet.snippetIcon?.file?.url} />
        </div>
      ) : (
        <div className={`snippet-icon-${index} icon-common`}>
          <Image
            src={formatHttpsUrl(
              `${snippet.snippetIcon?.file?.url}?w=90&fm=webp`
            )}
            width={90}
            height={90}
          />
        </div>
      )}
    </>
  );
};

SnippetIcon.propTypes = {
  snippet: PropTypes.shape({
    snippetIcon: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
      }),
    }),
  }),
  index: PropTypes.number,
};

const SnippetOneColumn = ({
  alignText,
  headerTextColor,
  snippetSectionHeader,
  snippets,
  sectionBackgroundColor = '',
  snippetHeaderColor = '',
}) => {
  const renderSnippetEntry = (snippetEntry, index) => {
    return snippetEntry?.contentType === 'snippet' ? (
      <div
        key={`snippet-section-key-${index}`}
        className={`snippet-section-${index}`}
        style={{
          textAlign: getContentTextAlignment(snippetEntry?.alignText),
        }}
      >
        {snippetEntry?.snippetIcon && (
          <SnippetIcon snippet={snippetEntry} index={index} />
        )}

        {snippetEntry?.snippetHeader && (
          <h4
            style={{
              color: extractColors(snippetEntry?.headerTextColor),
              textAlign: getContentTextAlignment(snippetEntry?.alignText),
            }}
          >
            {snippetEntry?.snippetHeader}
          </h4>
        )}
        {snippetEntry?.snippetText && (
          <div
            style={{
              color: extractColors(snippetEntry?.snippetTextColor),
              textAlign: getContentTextAlignment(snippetEntry?.alignText),
            }}
          >
            <Markdown markdown={snippetEntry?.snippetText} />
          </div>
        )}
        {snippetEntry?.snippetLinks?.length > 0 && (
          <div className="snippet-links">
            {snippetEntry?.snippetLinks?.map((snipLink, i) => (
              <DynamicLink
                sectionLink={snipLink}
                key={`snippet-link-${i + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    ) : (
      ''
    );
  };

  return (
    <SnippetOneColumnStyles
      $sectionBackgroundColor={extractColors(sectionBackgroundColor)}
      $snippetHeaderColor={snippetHeaderColor}
    >
      <div className="snippet-content">
        {snippetSectionHeader && (
          <div className="snippet-header">
            <h2
              style={{
                color: extractColors(headerTextColor),
                textAlign: getContentTextAlignment(alignText),
              }}
            >
              {snippetSectionHeader}
            </h2>
          </div>
        )}

        <div className="snippet-body">
          {snippets.map((snippet, index) => {
            return renderSnippetEntry(snippet, index);
          })}
        </div>
      </div>
    </SnippetOneColumnStyles>
  );
};

export default SnippetOneColumn;

SnippetOneColumn.propTypes = {
  alignText: PropTypes.any,
  headerTextColor: PropTypes.any,
  snippetSectionHeader: PropTypes.string,
  snippetLayout: PropTypes.string,
  snippets: PropTypes.arrayOf(
    PropTypes.shape({
      alignText: PropTypes.string,
      snippetHeader: PropTypes.string,
      headerTextColor: PropTypes.string,
      snippetIcon: PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      snippetText: PropTypes.string,
      snippetTextColor: PropTypes.string,
      snippetLinks: PropTypes.array,
      contentType: PropTypes.string,
    })
  ),
  sectionBackgroundColor: PropTypes.string,
  snippetHeaderColor: PropTypes.string,
};
