import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import { tablet_portrait_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const ImageModalVideoStyles = styled.div`
  position: relative;

  .preview-image {
    position: relative;
    width: 91.5vw;

    img {
      width: 100%;
      height: auto;
    }
  }

  .link-wrap {
    cursor: pointer;
  }

  .play-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: ${Colors.primary.white.standard};
    svg {
      width: 4rem;
      height: 4rem;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

      path {
        fill: currentColor;
      }
    }
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .preview-image {
      width: 96vw;
    }
  }
`;

export const VideoModal = styled.div`
  .modal-container {
    width: 90%;
    height: 90%;

    .modal-content {
      width: 100%;
      height: 100%;
      max-height: unset;
    }
  }
`;

export const VideoWrapper = styled.div`
  background-color: #000;
  height: 100%;
  position: relative;
  z-index: 100;

  .wistia_embed {
    height: 100%;
  }

  iframe {
    border: none;
    height: calc(100% - 70px);
    margin-top: 10px;
    width: 100%;
  }
`;

export default ImageModalVideoStyles;
