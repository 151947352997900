import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalStyles, { GlobalStyle } from './Modal.styled';

import {
  scrollLock,
  scrollUnlock,
  scrollLocked,
} from 'pubweb-smokey/dist/utils/utils';

import XSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';

const modalStack = [];

const Modal = ({ children, onClose }) => {
  const modalContainerRef = useRef();

  useEffect(() => {
    if (!scrollLocked()) {
      scrollLock();

      return () => {
        scrollUnlock();
      };
    }
  }, []);

  // Handle exit using escape key via eventListener. If enter key is processed here for closing modal, we have problems
  // when enter key press is required for other things like a button (e.g. login modal button).
  const handleKeyDown = (e) => {
    if (e.keyCode === 27 && onClose) {
      if (modalStack[modalStack.length - 1] == modalContainerRef) {
        onClose(e);
      }
    }
  };

  // Handle exit using enter key via onKeyDown.
  const handleExit = (e) => {
    if (e.keyCode === 13 && onClose) {
      onClose(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    modalStack.push(modalContainerRef);
    const focusableElements = modalContainerRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    focusableElements[0].focus();

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      modalStack.splice(modalStack.indexOf(modalContainerRef), 1);
    };
  }, [handleKeyDown]);

  return (
    <ModalStyles role="dialog" aria-modal="true" className="modal-component">
      <GlobalStyle />
      <div className="modal-filter" onClick={onClose}></div>
      <div className="modal-container" ref={modalContainerRef}>
        <div className="close-modal">
          <span tabIndex="0" onClick={onClose} onKeyDown={handleExit}>
            <XSvg viewBox="0 0 16 16" />
          </span>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </ModalStyles>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func,
};

export default Modal;
