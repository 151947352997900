import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import {
  desktop_breakpoint,
  desktop_container_maxwidth,
  desktop_breakpoint_extended,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const TextSectionStyles = styled.div`
  background-color: ${Colors.primary.white.standard};

  .text-section-container {
    padding: 96px 24px;

    .markdown {
      h1 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
      }
      h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
      }
      h3 {
        font-size: 18px;
        line-height: 29px;
        font-weight: 400;
      }
      p {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
      }
      p,
      p * {
        font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      }
    }

    .text-section-content,
    .markdown {
      h1,
      h2,
      h3,
      p {
        color: inherit;
      }
    }

    .text-section-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 1rem;
      letter-spacing: 0.5px;
    }

    .text-section-heading {
      margin-bottom: 16px;
      color: ${Colors.primary.black.standard};
    }

    .text-section-embedded-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .text-section-heading {
        margin-bottom: 16px;
        color: ${Colors.primary.black.standard};
      }

      .text-section-text,
      .markdown {
        p {
          color: inherit;
        }
        p + p {
          margin-top: 32px;
        }
      }

      .text-section-right {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        .text-section-image {
          position: relative;
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .markdown {
      a:link,
      a:visited {
        color: inherit;
      }
    }

    .text-section-links {
      list-style-type: none;
      text-align: left;

      li {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .disclaimer {
      margin-top: 16px;

      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint}px) {
    .text-section-container {
      width: 80%;
      margin: auto;
      padding: 48px 0;
      text-align: center;
      max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out

      .text-section-embedded-content {
        .text-section-right {
          margin-bottom: 0;
        }
        .text-section-heading {
          margin-top: 16px;
        }
      }
    }
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    .text-section-container {
      padding: 96px 0;

      .markdown {
        h1 {
          font-size: 25px;
          line-height: 30px;
          font-weight: 700;
        }
        h2 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
        }
        p {
          font-size: 18px;
          line-height: 29px;
          font-weight: 400;
        }
        p,
        p * {
          font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
        }
      }

      .text-section-embedded-content {
        text-align: left;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;

        &.left {
          flex-direction: row-reverse;
        }

        .text-section-left {
          width: 45%;
        }

        .text-section-right {
          width: 45%;
          justify-content: end;

          &.left {
            justify-content: start;
          }

          .preview-image {
            width: 38vw;
          }
        }
        .text-section-heading {
          margin-top: 0;
        }
      }

      .disclaimer {
        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
`;

export default TextSectionStyles;
