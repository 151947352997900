// Get all of the content of a particular content type type (e.g. 'textSection', 'snippetSection') from a content array (e.g. mainContent ).
export const getMainContent = (contentSection, type) => {
  let content = [];
  if (contentSection) {
    for (const cs of contentSection) {
      if (cs.contentType === type) {
        content.push(cs);
      }
    }
  }
  return content;
};

// The items in the prefooter actions need to be grouped (as needed) before processing as components
// This primarily applies to the inset CTAs. They are entered as indiviual items in the Contentful prefooter actions,
// but they need to be grouped together (2 or 3) if 'inset paired' or 'inset triple' before processing by the PreFooterSection component.
//
// The 'inset' items are expected to be entered in the order for display and it is expected the user will group 'inset paired' or 'inset triple'
// items together in Contentful as groups of 2 or 3.
// In other words, a user would enter: 'inset paired #1, inset paired #2, webform' rather than 'inset paired #1, , webform, inset paired #2'
//
export const groupPreFooterActions = (preFooterActions) => {
  let output = [];
  let insetCtas = [];
  preFooterActions?.map((action) => {
    // If the insetCtas array has content and the next item is not an inset CTA, push what we have and reset the local insetCtas.
    if (
      insetCtas.length > 0 &&
      action.ctaSize != 'Inset Paired' &&
      action.ctaSize != 'Inset Triple'
    ) {
      output.push({ contentType: 'cta', insetCtas });
      insetCtas = [];
    }

    switch (action.contentType) {
      case 'webForm':
        output.push(action);
        break;
      case 'cta':
        if (action.ctaSize == 'Inset Paired' && insetCtas.length < 2) {
          insetCtas.push(action);
          if (insetCtas.length == 2) {
            output.push({ contentType: 'cta', insetCtas });
            insetCtas = [];
          }
        } else if (action.ctaSize == 'Inset Triple' && insetCtas.length < 3) {
          insetCtas.push(action);
          if (insetCtas.length == 3) {
            output.push({ contentType: 'cta', insetCtas });
            insetCtas = [];
          }
        } else {
          output.push(action);
        }
        break;
    }
  });
  return output;
};
