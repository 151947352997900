import styled from 'styled-components';
import {
  tablet_landscape_breakpoint,
  tablet_portrait_breakpoint,
  desktop_breakpoint,
  desktop_container_maxwidth,
  lg_laptop_breakpoint,
  sm_desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const SnippetTwoColumnStyles = styled.div`
  padding: 32px 16px;

  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : Colors.primary.white.standard};

  .snippet-section-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }

  .snippet-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    position: relative;
  }

  .snippet-image {
    height: 62.7vw;
    position: relative;
  }

  .snippet-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .snippet-links {
    list-style: none;
    display: flex;
  }

  .snippet-link a {
    color: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    font-family: 'acumin-pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-decoration: none;

    .snippet-icon {
      display: inline-block;
      height: 14px;
      position: relative;
      width: 16px;
      transition: 0.5s;

      svg {
        pointer-events: none;
        margin-left: 5px;
        margin-top: 3px;
        position: absolute;
        top: calc(50% - 9px);

        * {
          fill: inherit;
        }
      }
    }
    :hover {
      transition-timing-function: ease-in;

      .snippet-icon {
        transform: translateX(30%);
      }
    }
  }

  .snippet-links,
  .snippet-header,
  .markdown {
    margin-top: 16px;

    p,
    li,
    a {
      color: inherit;
      text-align: inherit;
    }

    p,
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
    }

    a {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.3px;
      font-family: 'acumin-pro', sans-serif;
    }
  }

  .snippet-header {
    font-size: 22px;
    line-height: 26px;
    font-family: 'acumin-pro', sans-serif;
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .snippet-image {
      height: 65.7vw;
    }
  }

  @media screen and (min-width: ${desktop_breakpoint}px) {
    padding: 32px 0px;
    .snippet-section-container {
      width: 80%;
      margin: 0 auto;
    }
    .snippet-header {
      font-size: 25px;
      line-height: 30px;
    }
  }

  @media screen and (min-width: ${tablet_landscape_breakpoint}px) {
    .snippet-section-container {
      grid-template-columns: repeat(2, minmax(50%, 1fr));

      width: 80%;
      margin: auto;
      max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out
    }

    .snippet-image {
      height: 22.5vw;
    }

    .snippet-item img {
      width: 100%;
      height: 70%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media screen and (min-width: ${lg_laptop_breakpoint}px) {
    .snippet-image {
      height: 21.6vw;
    }
  }

  @media screen and (min-width: ${sm_desktop_breakpoint}px) {
    .snippet-image {
      height: 20.3vw;
    }
  }
`;
