import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import Markdown from '../Markdown/Markdown';
import {
  extractColors,
  formatHttpsUrl,
  auditMarkdownLinks,
} from '@utils/utils';
import { slugify } from '@utils/studioUtils';
import {
  getContentTextAlignment,
  getContentFlexAlignment,
} from '@utils/contentful';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';

const PageTransition = ({
  transitionTitle,
  alignText,
  anchorLink = '',
  navLinks = [],
  navLinksTextColor = '',
  transitionImage,
  overview,
  overviewTextColor,
  overviewLinks,
}) => {
  const stripChars = (slug) => {
    const firstChar = slug.charAt(0);

    return firstChar === '#' ? slug.slice(1, slug.length) : slug;
  };

  const handleAnchorClick = (anchorRef) => {
    const finalLink =
      anchorRef.indexOf('#') === 0 ? anchorRef.substring(1) : anchorRef;
    const targetAnchor = document.getElementById(finalLink);
    if (targetAnchor) {
      targetAnchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useLayoutEffect(() => {
    auditMarkdownLinks('transition-header-text');
    auditMarkdownLinks('transition-overview');
  }, []);

  return (
    <>
      {transitionTitle && (
        <div
          id={anchorLink?.length > 0 ? stripChars(slugify(anchorLink)) : null}
          className={
            navLinks?.length > 0 ? 'mini-nav-title' : 'transition-header'
          }
        >
          <div className="transition-header-text">
            <Markdown markdown={transitionTitle} />
          </div>
        </div>
      )}
      {(transitionImage || overview) && (
        <div className="transition-content">
          {transitionImage && (
            <div className="transition-image">
              <Image
                src={formatHttpsUrl(
                  `${transitionImage?.file?.url}?w=800&fm=webp`
                )}
                alt={transitionImage?.description}
                width={600}
                height={600}
              />
            </div>
          )}
          {overview && (
            <div className="transition-detail">
              <div
                className="transition-overview"
                style={{
                  color: extractColors(overviewTextColor),
                  textAlign: getContentTextAlignment(alignText),
                }}
              >
                <Markdown markdown={overview} />
              </div>
              <ul
                className="transition-links"
                style={{ justifyContent: getContentFlexAlignment(alignText) }}
              >
                {overviewLinks?.map((ol, i) => {
                  return (
                    <li
                      key={`transition-${slugify(ol.transitionTitle)}-${i + 1}`}
                      className="transition-link"
                      style={{
                        color: extractColors(ol?.buttonAndtextColors)?.[1],
                        fill: extractColors(ol?.buttonAndtextColors)?.[1],
                      }}
                    >
                      <Link href={ol.link}>
                        <div className="transition-link-text">
                          {ol.linkText}
                        </div>{' '}
                        <div>
                          <div className="transition-icon">
                            <StArrowRightSvg />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
      {navLinks?.length > 0 && (
        <ul className="mini-nav-links">
          {navLinks?.map((nl, j) => {
            const { anchorLink } = nl;

            return (
              <li
                key={`mini-nav-${slugify(nl.transitionTitle)}-${j + 1}`}
                className="mini-nav-link"
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAnchorClick(slugify(anchorLink));
                  }}
                  style={{
                    color: extractColors(
                      navLinksTextColor
                        ? navLinksTextColor
                        : nl.titleBackgroundColor
                    ),
                    fill: extractColors(
                      navLinksTextColor
                        ? navLinksTextColor
                        : nl.titleBackgroundColor
                    ),
                  }}
                >
                  <div className="mini-nav-link-text">
                    {anchorLink.indexOf('#') === 0
                      ? anchorLink.substring(1)
                      : anchorLink}{' '}
                    <div className="mini-nav-icon">
                      <StArrowRightSvg />
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default PageTransition;

PageTransition.propTypes = {
  transitionTitle: PropTypes.string,
  alignText: PropTypes.string,
  anchorLink: PropTypes.string,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      transitionTitle: PropTypes.string,
      anchorLink: PropTypes.string,
    })
  ),
  navLinksTextColor: PropTypes.string,
  transitionImage: PropTypes.object,
  overview: PropTypes.string,
  overviewTextColor: PropTypes.string,
  overviewLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      link: PropTypes.string,
      buttonAndtextColors: PropTypes.string,
    })
  ),
};
