import styled from 'styled-components';
import {
  md_mobile_breakpoint,
  tablet_portrait_breakpoint,
  tablet_landscape_breakpoint,
  desktop_container_maxwidth,
  md_laptop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

export const SnippetOneColumnStyles = styled.section`
  background-color: ${(props) =>
    props.$sectionBackgroundColor
      ? props.$sectionBackgroundColor
      : Colors.primary.white.standard};

  h2 {
    color: ${Colors.primary.white.standard};
    font-family: 'source-sans-pro', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
  }

  h4 {
    color: ${(props) =>
      props.$snippetHeaderColor
        ? props.$snippetHeaderColor
        : Colors.primary.white.standard};
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.35px;
  }

  p {
    color: ${Colors.primary.white.standard};
    font-size: 18px;
    line-height: 29px;
    letter-spacing: -0.3px;
  }

  .snippet-content {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .markdown {
    h1,
    h2,
    h3,
    p,
    li,
    a {
      color: inherit;
      text-align: inherit;
    }

    h1 {
      font-size: 32px;
      line-height: 38px;
    }
    h2 {
      font-size: 25px;
      line-height: 30px;
    }
    h3 {
      font-size: 20px;
      line-height: 30px;
    }
    img {
      display: inline-block;
    }
  }

  .snippet-header {
    margin-bottom: 50px;
  }

  .snippet-body {
    > div {
      margin-bottom: 40px;

      h4 {
        padding-bottom: 5px;
      }

      p {
        margin: 0 25px;
      }

      svg {
        margin-bottom: 30px;
        min-height: 60px; //spacing above/below icons, while loading icons
        min-width: 60px; //spacing around icons, while loading icons
        width: 60px;
      }

      .icon-common {
        min-height: 60px; //spacing above/below icons, while loading icons
        min-width: 60px; //spacing around icons, while loading icons
      }

      .link-txt {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 22px;
        font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
      }
    }
  }

  @media screen and (min-width: ${md_mobile_breakpoint}px) {
    .snippet-body {
      > div {
        h3,
        h4,
        p {
          margin: auto;
          width: 325px;
        }
      }
    }
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .snippet-body {
      > div {
        h3,
        h4,
        p {
          width: 512px;
        }
      }
    }
  }

  @media screen and (min-width: ${tablet_landscape_breakpoint}px) {
    .snippet-body {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: flex-start;
      justify-content: space-evenly;

      > div {
        margin-bottom: 20px;

        h3,
        h4,
        p {
          width: 250px;
        }
      }
    }
  }

  @media screen and (min-width: ${md_laptop_breakpoint}px) {
    .snippet-body {
      justify-content: space-around;

      > div {
        h3,
        h4,
        p {
          width: 250px;
        }
      }
    }
    .snippet-content {
      width: 80%;
      margin: auto;
      max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out
    }
  }
`;
