import styled, { css } from 'styled-components';
import {
  mobile_width_percentage,
  sm_mobile_breakpoint,
  tablet_portrait_breakpoint,
  desktop_breakpoint,
  desktop_breakpoint_extended,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors.js';

const sharedStyles = css`
  .transition-header {
    background-color: ${(props) =>
      props.$backgroundColor
        ? props.$backgroundColor
        : Colors.secondary.navy1.standard};
  }

  .mini-nav-title,
  .transition-header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: ${(props) =>
        props.$textColor ? props.$textColor : Colors.primary.white.standard};
    }

    h1 {
      font-size: 60px;
      line-height: 68px;
      letter-spacing: -0.525px;
    }

    h2 {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: -0.35px;
    }

    h3 {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: -0.35px;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    .transition-header {
      h2 {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.525px;
      }

      h3 {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
`;

export const PageTransitionStyles = styled.section`
  color: ${(props) =>
    props.$textColor ? props.$textColor : Colors.primary.black.standard};

  ${sharedStyles};

  .transition-header {
    padding: 64px 16px 80px;
  }

  .transition-content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin-top: -3rem;
    padding: 0 16px 32px;

    .transition-image {
      grid-area: 1 / 1;
      align-self: flex-start;

      img {
        width: 100%;
        height: auto;
      }
    }

    .transition-detail {
      grid-area: 2 / 1;

      .transition-overview {
        color: inherit;
        padding-top: 27px;
        padding-bottom: 20px;
        font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;

        .markdown {
          ul,
          ol {
            padding-left: 20px;
            list-style: disc;
          }

          p {
            color: inherit;
            word-wrap: break-word;
            a {
              color: inherit;
            }
          }
        }
      }

      .transition-links {
        list-style: none;
        display: flex;
      }

      .transition-link a {
        color: inherit;
        display: flex;
        @media only screen and (min-width: ${sm_mobile_breakpoint}px) {
          width: max-content;
        }
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: left;
        font-family: 'acumin-pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.3px;
        text-decoration: none;

        .transition-icon {
          transition: 0.5s;

          svg {
            pointer-events: none;
            margin-left: 5px;
            margin-top: 3px;

            * {
              fill: inherit;
            }
          }
        }
        :hover {
          transition-timing-function: ease-in;

          .transition-icon {
            transform: translateX(30%);
          }
        }
      }
    }
  }

  @media screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .transition-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 8%;

      .transition-image {
        grid-area: 1 / 1;
      }

      .transition-detail {
        grid-area: 1 / 2;

        .transition-overview {
          margin-top: 64px;
        }
      }
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    .transition-header {
      padding: 96px 0 48px;
    }

    .transition-header:has(+ .transition-content) {
      padding: 96px 0;
    }

    .transition-header-text {
      width: 80%;
      margin: auto;
      max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out
    }

    .transition-content {
      width: 80%;
      margin: -3rem auto 0;
      padding: 0 0 48px;
      max-width: ${desktop_container_maxwidth}px; // constrain when zoomed out
    }
  }
`;

export const MiniNavStyles = styled.section`
  background-color: ${(props) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : Colors.secondary.navy1.standard};
  color: ${(props) =>
    props.$textColor ? props.$textColor : Colors.primary.white.standard};

  .mini-nav-container {
    margin: 0 auto;
    padding: 64px 0;
    width: ${mobile_width_percentage}%;
  }

  ${sharedStyles};

  .mini-nav-title + .mini-nav-links {
    padding-top: 64px;
  }

  .mini-nav-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0;
  }

  .mini-nav-link {
    margin-bottom: 16px;

    a {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-decoration: none;
    }

    .mini-nav-icon {
      display: inline-block;
      height: 14px;
      margin-left: 7px;
      position: relative;
      width: 16px;
    }

    .mini-nav-link-text {
      font-family: 'acumin-pro', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.3px;
      margin-right: 0.5em;
    }

    svg {
      margin-left: 0.2em;
      position: absolute;
      top: calc(50% - 14px);
      transition: all 0.1s ease-in-out 0s;
      transform: translateY(40%);

      * {
        fill: inherit;
      }
    }

    &:hover {
      svg {
        transform: translate(30%, 40%);
      }
    }
  }

  @media only screen and (min-width: ${tablet_portrait_breakpoint}px) {
    .mini-nav-links {
      flex-wrap: wrap;
    }

    .mini-nav-link {
      margin-right: 24px;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .mini-nav-container {
      width: 80%;
      margin: auto;
      max-width: 1200px;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    .mini-nav-title {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }

      h3 {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .mini-nav-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin: 0;
    }
  }
`;
